<template>
  <PageContent
    class="fiche-manuel-numerique"
    :nav-titles="navTitles"
    :prev-page="prevPage"
  >
    <template #header-right>
      <ButtonClassic
        v-if="hasPerm('view_articlepapier')
          && product && product.articles_papier && product.articles_papier.length"
        balise-type="router-link"
        :label="$t('produit.version-papier')"
        variant="ghost"
        color="primary"
        icon="right"
        :router-link-obj="{
          name: 'catalogues_livres_manuels_papier_item', params: { id: product.articles_papier[0].id, },
        }"
      >
        <template #right-icon>
          <IconPapierAlt />
        </template>
      </ButtonClassic>
      <Badge
        v-if="badgeDispoInfo.variant && !computedIsProductAvailable"
        id="header_badge"
        :variant="badgeDispoInfo.variant"
        :title="badgeDispoInfo.title"
        medium
      />
      <ButtonGroup>
        <ButtonClassic
          variant="solid"
          icon="right"
          @click="copyToClipboard(`${currentUrl}?id_organisme=${organismeActuel.id}`)"
        >
          <template #right-icon>
            <UilShareAlt />
          </template>
        </ButtonClassic>
      </ButtonGroup>
    </template>

    <template
      v-if="product"
      #content
    >
      <Toast
        v-if="product.offres && !product.offres[0].catalogue_actif"
        :title="$t('toast.arret-commandes')"
        :content="$t('commande.les-commandes-sont-fermees')"
        show-icon
        hide-close-btn
      />
      <ManuelNumeriqueOverview
        :product="product"
        @add-to-list="checkToList"
      />
      <ProductDescription :product="product" />
      <!--TODO Caché temporairement, modifier css dessous aussi (#product_offers) une fois corrigé.-->
      <ManuelNumeriqueFeatures
        :product="product"
      />
      <div class="grid-zone">
        <ManuelNumeriqueOffers
          :product="product"
          @add-to-list="checkToList"
        />
        <ManuelNumeriqueRequired :product="product" />
      </div>
      <ProductRelations
        :products="similarProducts"
        :is-loading="similarProductsAreLoading"
      />
      <ModalListesSelection
        id="modal_listes_selection"
        :listes="activeLists"
        @open-creation-liste="$modal.show('modal_listes_creation')"
      />
    </template>
    <template
      v-else
      #content
    >
      <div>
        <InfiniteLoader />
      </div>
    </template>
  </PageContent>
</template>

<script>
import {
  ButtonClassic,
  ButtonGroup,
  Toast,
  PageContent,
  InfiniteLoader,
  copyToClipboard,
} from "@lde/core_lde_vue";

import Badge from "@/components/Badge.vue";
import ManuelNumeriqueOverview from "@/components/manuels_numeriques/ManuelNumeriqueOverview.vue";
import ProductDescription from "@/components/products/ProductDescription.vue";
import ManuelNumeriqueFeatures from "@/components/manuels_numeriques/ManuelNumeriqueFeatures.vue";
import ManuelNumeriqueOffers from "@/components/manuels_numeriques/ManuelNumeriqueOffers.vue";
import ManuelNumeriqueRequired from "@/components/manuels_numeriques/ManuelNumeriqueRequired.vue";
import ProductRelations from "@/components/products/ProductRelations.vue";
import IconPapierAlt from "@/components/icons/IconPapierAlt.vue";
import IconImage from "@/components/icons/IconImage.vue";
import ModalListesSelection from "@/components/modals/ModalListesSelection.vue";

import mixinFicheProduit from "@/mixins/mixinFicheProduit";
import mixinDisponibilite from "@/mixins/mixinDisponibilite";

import { UilShareAlt } from "@iconscout/vue-unicons";

import Api from "@/modules/axios";

import { mapGetters } from "vuex";

/**
 * Vue d'un manuel numérique en particulier avec toutes ses informations.
 */
export default {
  name: "FicheManuelNumerique",
  components: {
    PageContent,
    Toast,
    Badge,
    ButtonGroup,
    ButtonClassic,
    ManuelNumeriqueOverview,
    ProductDescription,
    ManuelNumeriqueFeatures,
    ManuelNumeriqueOffers,
    ManuelNumeriqueRequired,
    ProductRelations,
    ModalListesSelection,
    InfiniteLoader,
    IconPapierAlt,
    UilShareAlt,
  },
  mixins: [mixinFicheProduit, mixinDisponibilite],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevPage = from;
    });
  },
  data() {
    return {
      navTitles: [
        { icon: IconImage, anchorId: "manuel_numerique_overview" },
        { label: this.$t("produit.description"), anchorId: "product_description" },
        { label: this.$t("produit.detail-offres"), anchorId: "product_offers" },
        { label: this.$t("produit.requis"), anchorId: "product_required" },
      ],
      offers: [],
      prevPage: null,
      currentUrl: window.location.href,
      idOrganisme: null,
      similarProducts: [],
      similarProductsAreLoading: false,
    };
  },
  computed: {
    ...mapGetters(["organismeActuel", "isGE", "hasPerm"]),
    isCommandeClosed() {
      return this.product.offres?.some((offre) => !offre.catalogue_actif);
    },
  },
  methods: {
    copyToClipboard,
    /**
     * Récupère les informations du produit.
     * @returns {Promise} Infos du produit.
     */
    fetchProduct() {
      return Api().get(`/manuel_numerique/${this.$route.params.id}/`)
        .then(({ data }) => {
          this.product = data;
          this.product.offres.forEach((element) => {
            element.quantite = element.quantite_min || 1;
          });
          if (this.product.offre_defaut) {
            this.product.offre_defaut.quantite = this.product.offre_defaut.quantite_min || 1;
          }

          // On crée un objet d'offres groupées sur le champ licence
          this.product.offres_group = data.offres.reduce((r, a) => {
            r[a.licence] = r[a.licence] || [];
            r[a.licence].push(a);
            return r;
          }, Object.create(null));

          if (this.$route.hash) {
            setTimeout(() => { window.location.href = this.$route.hash; }, 1);
          }

          this.fetchSimilarArticles();
        });
    },
    /**
     * Récupère les articles de même collection et ceux de même éditeur.
     */
    fetchSimilarArticles() {
      this.similarProductsAreLoading = true;
      return Api().get(`/manuel_numerique/${this.product.id}/related/`)
        .then((res) => {
          this.similarProducts = res.data;
          this.similarProductsAreLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/fiche_produit.scss";
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

.fiche-manuel-numerique {
  .toast { margin-bottom: var.$space-large; }
}
</style>
